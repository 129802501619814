import { APIOutput, Navigation } from "@amzn/ask-legal-domain";
import { StatusIndicator } from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { getNavigationTabContent } from "../home-page-widgets/navigation-widget";
import "../../styles/component/layout/AppHeader.scss";

export interface AppNavigationHeaderProps {
    instanceId: string;
    isMenuOpen: boolean;
}

export const AppNavigationHeader = (props: AppNavigationHeaderProps) => {
    const context = React.useContext(AppContext);
    const [loadingProps, setLoadingProps] = React.useState<{}>();
    const [errorProps, setErrorProps] = React.useState<Error>();
    const [navigationData, setNavigationData] = React.useState<Navigation.Data>();
    const [activeNavTab, setActiveNavTab] = React.useState<string>(null);
    const [activeNavGroup, setActiveNavGroup] = React.useState<string>(null);
    const [activeNavLink, setActiveNavLink] = React.useState<string>(null);

    const handleActiveNavTab = (id: string) => {
        if (activeNavTab === id) {
            setActiveNavTab(null);
        } else {
            setActiveNavTab(id);
        }
    };

    const handleActiveNavGroup = (id: string) => {
        if (activeNavGroup === id) {
            setActiveNavGroup(null);
        } else {
            setActiveNavGroup(id);
        }
    };

    const handleActiveNavLink = (id: string) => {
        if (activeNavLink === id) {
            setActiveNavLink(null);
        } else {
            setActiveNavLink(id);
        }
    };

    const init = async () => {
        setLoadingProps({});
        try {
            const loadNavigationOutput = await context.getNavigationAPI().load({
                navigationId: props.instanceId,
            });
            const output = APIOutput.fromRaw<Navigation.Data>(loadNavigationOutput.data);
            if (output.isOk()) {
                setNavigationData(output.data);
            } else if (output.isErr()) {
                if (output.err.code === 403) {
                    setErrorProps({
                        name: "Forbidden",
                        message: "You do not have permission to access this resource"
                    });
                } else if (output.err.code === 404) {
                    setErrorProps({
                        name: "Not Found",
                        message: "This resource does not exist"
                    });
                } else {
                    setErrorProps({
                        name: "Error",
                        message: output.err.message
                    });
                }
                if (navigationData) {
                    setNavigationData(undefined);
                }
            }
        } catch (error) {
            setErrorProps(error);
            if (navigationData) {
                setNavigationData(undefined);
            }
        } finally {
            setLoadingProps(undefined);
        }
    };


    React.useEffect(() => {
        init();
    }, []);

    return <>
        <nav className={`awsui-polaris-dark-mode nav-old-navigation${props.isMenuOpen ? " active" : ""}`}>
            {loadingProps && <>...</>}
            {errorProps && <StatusIndicator type="error">{errorProps.name} : {errorProps.message}</StatusIndicator>}
            {navigationData && (
                navigationData.tabs.map(tab =>
                    <div className="nav-old-navtab">
                        <button
                            id={tab.id}
                            className="nav-old-tabname"
                            aria-expanded={activeNavTab === tab.id}
                            aria-controls={tab.name}
                            onClick={() => handleActiveNavTab(tab.id)}
                        >
                            <span className="nav-old-tab-text">{tab.name}</span>
                        </button>
                        <div onMouseLeave={() => {
                            setActiveNavTab(null);
                            setActiveNavGroup(null);
                            setActiveNavLink(null);
                        }}
                            className={`navtab-content ${activeNavTab === tab.id ? "navtab-display" : ""}`}
                            aria-controlledBy={tab.id}
                            id={tab.name}
                        >
                            {getNavigationTabContent({
                                tabItems: tab.items,
                                activeNavGroup: activeNavGroup,
                                activeNavLink: activeNavLink,
                                handleActiveNavGroup: handleActiveNavGroup,
                                handleActiveNavLink: handleActiveNavLink,
                                tabColumnCount: props.isMenuOpen ? 1 : undefined
                            })}
                        </div>
                    </div>
                )
            )}
        </nav>
    </>;
};
