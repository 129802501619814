import {
    EntityPermissionOperationNames,
    EntityType,
    IEntityPermission,
    InstanceFactory,
    REPORT_BUG_LINK,
    FEATURE_REQUEST_LINK
} from "@amzn/ask-legal-domain";
import { Icon } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI2 } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { Preference } from "../../setup/preference";
import LegalAiCompanionComponent from "../chatbot/legal-ai-companion";
import { AppURLFactory } from "../../factory/app-url-factory";
import "../../styles/component/layout/Footer.scss";

export type FooterType = "InstanceHome" | "AdminPortal" | "LivePage";

export const SherpaFooter = (props: {
    variant: FooterType;
    entityId?: string;
}) => {
    const context = React.useContext(AppContext);
    const isAuthorizedRunner = useAPI2(
        context.getEntityPermissionAPI().isAuthorized
    );
    const checkPageAdminAccessRunner = useAPI2(
        context.getPageAPI().checkAdminAccess
    );
    const loadInstanceRunner = useAPI2(
        context.getInstanceAPI().load
    );

    const [feedbackLink, setFeedbackLink] = React.useState<string>();
    const [adminLink, setAdminLink] = React.useState<string>();

    React.useEffect(() => {
        if (!props.entityId) return;
        const instanceId = InstanceFactory.fromEntityId(props.entityId);
        loadInstanceRunner.invoke(instanceId);
        if (props.variant === "InstanceHome") {
            isAuthorizedRunner.invoke(
                IEntityPermission.IsAuthorizedInput.create(
                    instanceId,
                    EntityType.Instance,
                    EntityPermissionOperationNames.CAN_VIEW
                )
            );
        } else if (props.variant === "LivePage") {
            checkPageAdminAccessRunner.invoke(props.entityId);
        }
    }, [props.entityId]);

    React.useEffect(() => {
        if (isAuthorizedRunner.status === "Succeeded" && isAuthorizedRunner.output) {
            setAdminLink(`${window.location.origin}/#/instance/${InstanceFactory.fromEntityId(props.entityId)}/admin`);
        }
    }, [isAuthorizedRunner.status]);

    React.useEffect(() => {
        if (checkPageAdminAccessRunner.status === "Succeeded") {
            setAdminLink(window.location.origin + AppURLFactory.getPage(props.entityId, { subPath: "admin" }) );
        }
    }, [checkPageAdminAccessRunner.status]);

    React.useEffect(() => {
        if (loadInstanceRunner.status === "Succeeded") {
            if (!!loadInstanceRunner.output.feedbackTicketTemplate && loadInstanceRunner.output.feedbackTicketTemplate.length > 0) {
                setFeedbackLink(loadInstanceRunner.output.feedbackTicketTemplate);
            }
        }
    }, [loadInstanceRunner.status]);

    return (
        <>
            <div>
                {context.hasLab(Preference.Lab.Chatbot) && props.entityId && (<LegalAiCompanionComponent instanceId={InstanceFactory.fromEntityId(props.entityId)}/>)}
            </div>
            <div className={`sherpa-footer ${props.variant === "InstanceHome" ? "footer-instance-home" : "footer-other"}`}>
                <div className="sherpa-footer-row awsui-polaris-dark-mode">
                    <a
                        className="sherpa-footer-item"
                        href={REPORT_BUG_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Report technical issues"
                    >
                        <Icon  name="bug" />
                        <div className="sherpa-footer-item-text">Report a technical bug</div>
                        <div className="sherpa-footer-item-text-short">Bug</div>
                    </a>
                    <a
                        className="sherpa-footer-item"
                        href={FEATURE_REQUEST_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Submit a feature request"
                    >
                        <Icon name="gen-ai" />
                        <div className="sherpa-footer-item-text">Feature request</div>
                        <div className="sherpa-footer-item-text-short">Feature</div>
                    </a>
                    {feedbackLink &&
                        <a
                            className="sherpa-footer-item"
                            href={feedbackLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Report content issues"
                        >
                            <Icon name="contact" />
                            <div className="sherpa-footer-item-text">Provide content feedback</div>
                            <div className="sherpa-footer-item-text-short">Feedback</div>
                        </a>}
                    {adminLink &&
                        <a
                            className="sherpa-footer-item"
                            href={adminLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Access admin portal"
                        >
                            <Icon name="user-profile" />
                            <div className="sherpa-footer-item-text">Admin Portal</div>
                            <div className="sherpa-footer-item-text-short">Admin</div>
                        </a>}
                </div>
            </div>
        </>
    );
};