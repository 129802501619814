import {
    IContainer,
    Page,
    EntityFactory,
    ContainerRef
} from "@amzn/ask-legal-domain";
import { ExpandableSection, Header, SpaceBetween, Spinner, Toggle } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { HashLink } from "react-router-hash-link";
import { AskLegalLayoutUtils } from "../layout/AskLegalLayout";
import { AppContext } from "../../setup/context";
import { FavorButton } from "../common/FavorButton";
import { UIModel } from "../../model/ui-model";
import { ViewContainerContent } from "./modal-content/ViewContainerContent";
import { ContainerLinkCopyButton } from "./ContainerButtons";
import { getQueryParameterMap, isCompareFeatureActivated, scrollWithDelayInMs } from "../../utils/common-utils";
import { ContainerDiffView } from "./ContainerDiffView";
import { AppURLFactory } from "../../factory/app-url-factory";
import "../../styles/custom-container-header.scss";
const BACK_TO_TOP = require("../../assets/back-to-top.png").default;
const EDIT_BUTTON = require("../../assets/edit.png").default;

export const ContainerView = (props: ContainerViewProps) => {
    const context = React.useContext(AppContext);
    const loadContainerRunner = useAPI(
        context.getContainerAPI().loadVersion
    );
    const loadContainerVersionRunner = useAPI(
        context.getContainerAPI().loadVersion
    );
    const [expandFlag_1, setExpandFlag_1] = React.useState<boolean>(false);
    const [expandFlag_2, setExpandFlag_2] = React.useState<boolean>(false);
    const [showDiff, setShowDiff] = React.useState<boolean>(false);
    const [liveContainerData, setLiveContainerData] = React.useState<any>();

    const isFavoritedState = UIModel.State.use<boolean>({
        initialValue: false
    });

    React.useEffect(() => {
        let currentExpanded: boolean = props.isExpanded;
        if (!!expandFlag_1) {
            currentExpanded = !currentExpanded;
            setExpandFlag_1(false);
        }
        if (!!expandFlag_2) {
            currentExpanded = !currentExpanded;
            setExpandFlag_2(false);
        }
        if (currentExpanded !== props.isExpanded) {
            props.pageControlDispatch({
                type: "toggle",
                index: props.containerIndex
            });
        }
    }, [expandFlag_1, expandFlag_2]);

    React.useEffect(() => {
        if (loadContainerRunner.status === "Succeeded") {
            isFavoritedState.setValue(loadContainerRunner.data.output.isFavorited);
        }
    }, [loadContainerRunner.status]);

    React.useEffect(() => {
        loadContainerRunner.submitRun(props.containerRef);
        if (
            !props.showCompare ||
            !props.liveContainerRef ||
            props.liveContainerRef.version === props.containerRef.version ||
            !isCompareFeatureActivated(props.liveContainerRef.containerType)
        ) return;
        loadContainerVersionRunner.submitRun(props.liveContainerRef);
    }, [
        props.containerRef.id,
        props.containerRef.version
    ]);

    React.useEffect(() => {
        if (loadContainerRunner.status === "Succeeded") {
            !!props.onLoadComplete && props.onLoadComplete(loadContainerRunner.data.output);
            const queryParamMap = getQueryParameterMap();
            if (queryParamMap.get("container") === props.containerRef.id) {
                const targetContainerId = queryParamMap.get("container");
                scrollWithDelayInMs(700, targetContainerId);
            }
        }
    }, [loadContainerRunner.status]);

    React.useEffect(() => {
        if (loadContainerVersionRunner.status === "Succeeded") {
            setLiveContainerData(loadContainerVersionRunner.data.output);
        }
    }, [loadContainerVersionRunner.status]);
    return (
        <React.Fragment>
            {loadContainerRunner.status === "Succeeded" &&
                <ExpandableSection variant="container"
                    onChange={() => setExpandFlag_1(true)}
                    className="custom-container"
                    expanded={props.isExpanded}
                    header={
                        <Header
                            actions={
                                <SpaceBetween size="xxxs" direction="horizontal" className="awsui-polaris-dark-mode horizontal-center">
                                    {(
                                        !!liveContainerData || !!props.showCompare &&
                                        (!props.liveContainerRef && isCompareFeatureActivated(props.containerRef.containerType))) &&
                                        <span
                                            title="Compare with live"
                                            onClick={() => setExpandFlag_2(true)}
                                        >
                                            <Toggle
                                                onChange={({ detail }) => setShowDiff(detail.checked)}
                                                checked={showDiff}
                                            ><b>Compare</b></Toggle>
                                        </span>}
                                    {!liveContainerData && loadContainerVersionRunner.status === "Running" && <Spinner />}
                                    {!!props.showEditButton && <span onClick={() => setExpandFlag_2(true)}>
                                        <a href={AppURLFactory.getContainer(
                                            props.containerRef.id,
                                            {
                                                type: "draft",
                                                params: new Map([
                                                    ["edit", "true"]
                                                ])
                                            }
                                        )} target="_blank">
                                            <img alt="Edit container" title="Edit container" height="26px" src={EDIT_BUTTON} />
                                        </a>
                                    </span>}
                                    <span onClick={() => setExpandFlag_2(true)}>
                                        <ContainerLinkCopyButton containerId={props.containerRef.id} />
                                    </span>
                                    {props.location === "Main" && <span title="Navigate to top" onClick={() => setExpandFlag_2(true)}>
                                        <a style={{ textDecoration: "none" }}>
                                            <HashLink
                                                smoothed to={"#"}
                                                style={{ textDecoration: "none" }}
                                                scroll={el => AskLegalLayoutUtils.scrollWithOffset(el, -80)}>
                                                <span><img alt="Back to top" height="28px" src={BACK_TO_TOP} /></span>
                                            </HashLink>
                                        </a>
                                    </span>}
                                </SpaceBetween>
                            }>
                            <p className="dark-header-p">{loadContainerRunner.data.output.containerMetadata.title}</p>
                            {props.showFavoriteButton && <span onClick={() => setExpandFlag_2(true)}>
                                <FavorButton
                                    size="normal"
                                    isFavoritedState={isFavoritedState}
                                    targetEntityRef={EntityFactory.toEntityRef(
                                        loadContainerRunner.data.output.containerMetadata
                                    )}
                                />
                            </span>}
                        </Header>
                    }>
                    <a id={loadContainerRunner.data.output.containerMetadata.id}>
                        {!showDiff &&
                            <ViewContainerContent
                                containerMetadata={loadContainerRunner.data.output.containerMetadata}
                                content={loadContainerRunner.data.output.loadedContent}
                                pageSection={props.location}
                                expandCollapseAllTrigger={props.expandCollapseAllTrigger}
                                showEditControls={props.showEditButton}
                            />
                        }
                        {!!showDiff &&
                            <ContainerDiffView
                                newContainer={loadContainerRunner.data.output}
                                oldContainer={liveContainerData}
                            />
                        }
                    </a>
                </ExpandableSection>
            }
            {loadContainerRunner.status === "Error" &&
                <ExpandableSection variant="container" className="custom-container" defaultExpanded={true} header={<Header><p className="dark-header-p">
                    Error Loading Container
                </p></Header>}>
                    Error Loading Container
                </ExpandableSection>
            }
            {(loadContainerRunner.status === "Running" || loadContainerRunner.status === "New") &&
                <ExpandableSection variant="container" className="custom-container" defaultExpanded={true} header={<Header><p className="dark-header-p">
                    Loading...
                </p></Header>}>
                    <Spinner />
                </ExpandableSection>
            }
        </React.Fragment>
    );
};

export interface ContainerViewProps {
    containerRef: ContainerRef;
    location?: Page.SectionLocation;
    isExpanded: boolean;
    containerIndex: number;
    /**
     * If set to false, this container cannot be favorited, and favor button is hidden
     */
    showFavoriteButton?: boolean;
    /**
     * If set to false, hides compare diff toggle
     */
    showCompare?: boolean;
    /**
     * If set to true, show edit button that will navigate to draft page
     */
    showEditButton?: boolean;
    /**
     * Live container reference for computing diff
     */
    liveContainerRef?: ContainerRef;
    onLoadComplete?: (loadedData: IContainer.LoadContainerOutput) => void;
    pageControlDispatch: (params: { type: "expand-all" | "collapse-all" | "toggle", index: number }) => void;
    expandCollapseAllTrigger?: { value: string; };
}